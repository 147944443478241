import { useEffect, useState } from "react";
import { API_URL } from "../config/constants";
import { Employee, Office } from "../models";
import { getAccessToken } from "../services/authService";

export const useEmployees = (office?: Office) => {
  const [allEmployees, setAllEmployees] = useState(Array<Employee>);
  const [employees, setEmployees] = useState(Array<Employee>);
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const filterSetEmployees = (empls: Array<Employee>) => {
      let employees: Array<Employee> = empls;

      if (office) {
        employees = empls.filter((empl) => empl.officeName == office);
      }

      setEmployees(employees.sort((a, b) => a.name.localeCompare(b.name)));
    };

    if (allEmployees.length == 0) {
      setIsLoading(true);
      void getAccessToken().then((token) => {
        fetch(API_URL + "/api/metadata/employees", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then(
            (result: Array<Employee>) => {
              setIsLoading(false);
              setAllEmployees(result);
              filterSetEmployees(result);
            },
            (error: Error) => {
              setIsLoading(false);
              setError(error);
            }
          );
      });
    } else {
      filterSetEmployees(allEmployees);
    }
  }, [office]);

  return {
    allEmployees: allEmployees,
    employees: employees,
    isLoading: isLoading,
    error: error,
  };
};
