import { Popover } from "antd";
import { endOfDay } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { TimelineEvents } from "react-headless-timeline";
import { EntryAbsence } from "../models";
import { getAbsenceBackground, getAbsenceColour } from "./EventUtils";
import { PopoverComponent } from "./PopoverComponent";

export type CalendarEventsProps = {
  entries: Array<EntryAbsence>;
  colWidth: number;
  employeeId: number;
  employeeName: string;
};

export function CalendarEvents({ entries, colWidth, employeeId, employeeName }: CalendarEventsProps) {
  return (
    <div style={{ position: "relative", minWidth: 0 }}>
      <span
        style={{
          width: `calc(100% + ${colWidth}px`,
          position: "absolute",
          left: "0%",
          height: 1,
          background: "#ededed",
        }}
      />
      <TimelineEvents
        render={({ getEventStyles }) => (
          <>
            {entries.map((event) => {
              const startDate = zonedTimeToUtc(new Date(event.from), "UTC");
              const endDate = zonedTimeToUtc( endOfDay(new Date(event.thru)), "UTC");

              return (
                <Popover
                  key={`employee-${employeeId}-${startDate.toISOString()}-${endDate.toISOString()}`}
                  content={<PopoverComponent event={event} employeeName={employeeName} />}
                  overlayStyle={{ width: 250 }}
                  placement={"bottom"}
                >
                  <div
                    style={{
                      backgroundColor: getAbsenceColour(event.type),
                      borderRadius: 14,
                      backgroundImage: getAbsenceBackground(event),
                      height: 23,
                      transform: "translateY(25%)",
                      ...getEventStyles({ startDate: startDate, endDate: endDate }),
                    }}
                  />
                </Popover>
              );
            })}
          </>
        )}
      />
    </div>
  );
}
