import { TimelineProvider } from "react-headless-timeline";
import { CalendarHeader } from "./CalendarHeader";
import { differenceInCalendarDays } from "date-fns";
import { CalendarEvents } from "./CalendarEvents";
import { Office } from "../models";
import { useEmployees, useCalendarEntries } from "../hooks";
import { Skeleton } from "antd";
import { zonedTimeToUtc } from "date-fns-tz";
import { useEffect, useState } from "react";

type CalendarComponentProps = {
  office?: Office;
  startDate: Date;
  endDate: Date;
};

export function CalendarComponent({
  startDate,
  endDate,
  office,
}: CalendarComponentProps) {
  const {
    allEmployees,
    employees,
    isLoading: isLoadingEmployee,
  } = useEmployees(office);
  const { entries, isLoading: isLoadingEntries } = useCalendarEntries(
    startDate,
    endDate,
    office,
    allEmployees
  );

  const isLoading = isLoadingEmployee || isLoadingEntries;

  const width = useViewportWidth();
  const cells = differenceInCalendarDays(endDate, startDate) + 1;

  const [colWidth, setColWidth] = useState(calculateCellWidth(cells, width));

  useEffect(() => {
    setColWidth(calculateCellWidth(differenceInCalendarDays(endDate, startDate) + 1, width));

  }, [startDate, endDate, width]);

  const gridWidth = (cells - 1) * colWidth;

  return isLoading ? (
    <Skeleton
      active
      title={false}
      paragraph={{
        rows: employees.length == 0 ? 0 : employees.length - 2,
      }}
    />
  ) : (
    <TimelineProvider
      startDate={zonedTimeToUtc(startDate, "UTC")}
      endDate={zonedTimeToUtc(endDate, "UTC")}
    >
      <ul className="calendar" style={{ height: employees.length * 34 + 48 }}>
        <li
          key={"header"}
          style={{
            gridTemplateColumns: `150px ${gridWidth}px`,
          }}
        >
          <span
            className="employee-overlap"
            style={{
              height: 48,
            }}
          >
            Mitarbeiter
          </span>
          <CalendarHeader
            cells={cells}
            employeeCount={employees.length}
            colWidth={colWidth}
          />
        </li>
        {employees.map((empl) => {
          const employeeEntry = entries.find(entry => entry.employeeName == empl.name);

          return (
            <li
              key={`employee-${empl.id}`}
              style={{
                gridTemplateColumns: `150px ${gridWidth}px`,
              }}
            >
              <span
                className="employee-overlap"
                style={{
                  height: 34,
                  lineHeight: "34px",
                }}
              >
                {empl.name}
              </span>
              <CalendarEvents entries={employeeEntry != undefined ? employeeEntry.absences : []} colWidth={colWidth} employeeId={empl.id} employeeName={empl.name} />
            </li>
          );
        })}
      </ul>
    </TimelineProvider>
  );
}

function calculateCellWidth(cells: number, width: number): number {
  const relativeWidth = Math.round((width - 205) / cells);

  let minimumWidth = 40; // quarter
  if (cells <= 31) minimumWidth = 55; // month and week

  return relativeWidth > minimumWidth ? relativeWidth : minimumWidth;
}

function useViewportWidth(): number {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return width;
}
