import { add, endOfMonth, endOfQuarter, parse, startOfDay } from "date-fns";

export function getRangeEndpoints(
  division: "week" | "month" | "quarter",
  period: number,
  year: number
): { start: Date; end: Date } {
  let start: Date;
  let end: Date;

  if (division == "week") {
    start = parse(`${period} ${year}`, "I RRRR", new Date());
    end = add(start, { days: 6 });
  } else if (division == "month") {
    start = parse(`${period} ${year}`, "L yyyy", new Date());
    end = startOfDay(endOfMonth(start));
  } else if (division == "quarter") {
    start = parse(`${period} ${year}`, "q yyyy", new Date());
    end = startOfDay(endOfQuarter(start));
  } else {
    console.error("Function used with wrong parameters");
  }

  return {
    start: start,
    end: end,
  };
}

export function nextRange(
  currentPeriod: number,
  currentYear: number,
  division: "week" | "month" | "quarter"
): { period: number; year: number } {
  let period = currentPeriod + 1;
  let year = currentYear;

  if (
    (division == "week" && period == 53) ||
    (division == "month" && period == 13) ||
    (division == "quarter" && period == 5)
  ) {
    period = 1;
    year = year + 1;
  }

  return {
    period: period,
    year: year,
  };
}

export function prevRange(
  currentPeriod: number,
  currentYear: number,
  division: "week" | "month" | "quarter"
): { period: number; year: number } {
  let period = currentPeriod - 1;
  let year = currentYear;

  if (period == 0) {
    year = year - 1;
    if (division == "week") {
      period = 52;
    } else if (division == "month") {
      period = 12;
    } else if (division == "quarter") {
      period = 4;
    } else {
      console.error("Function used with wrong parameters");
    }
  }

  return {
    period: period,
    year: year,
  };
}
