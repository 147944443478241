import { Configuration, PopupRequest } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: "c8320bbf-c4cf-4602-8630-5ea24a3f2b2c",
    authority:
      "https://login.microsoftonline.com/6be90bdd-1b52-4046-b937-85d55e55843e",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
};

export const loginRequest: PopupRequest = {
  scopes: ["User.Read"],
};
