import { useState } from "react";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./config/authConfig";
import { Button, Tooltip } from "antd";
import { InfoCircleOutlined, LogoutOutlined } from "@ant-design/icons";
import { CalendarComponent } from "./Calendar/CalendarComponent";
import { Office } from "./models";
import { CalendarNavigation } from "./Calendar/CalendarNavigation";
import { startOfDay, startOfMonth, endOfMonth } from "date-fns";

function InnerCalendarView() {
  const now = new Date();
  const [currentOffice, setCurrentOffice] = useState<Office | "Alle">("Alle");
  const [startDate, setStartDate] = useState(startOfMonth(now));
  const [endDate, setEndDate] = useState(startOfDay(endOfMonth(now)));

  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const name = account.name ?? account.username;

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: 65,
          backgroundColor: "#77263c",
          padding: 10,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", columnGap: 5 }}>
          <h1 style={{ color: "#fff", marginBottom: 0 }}>Abwesenheitskalender</h1>
          <Tooltip title="Es kann bis zu einem Tag dauern, bis eingetragene Abwesenheiten angezeigt werden.">
            <InfoCircleOutlined style={{ color: "#fff", fontSize: "large" }}/>
          </Tooltip>
        </div>
        <div style={{ display: "flex", columnGap: 10, alignItems: "center" }}>
          <h3 style={{ color: "#fff", marginBottom: 0 }}>{name}</h3>
          <Button
            type="default"
            shape="round"
            icon={<LogoutOutlined />}
            onClick={() => {
              void instance.logout();
            }}
          >
            Logout
          </Button>
        </div>
      </div>
      <div style={{ margin: "0px 20px", height: "calc(100vh - 65px)", display: "flex", flexDirection: "column" }}>
        <CalendarNavigation
          setOffice={setCurrentOffice}
          setEnd={setEndDate}
          setStart={setStartDate}
        />
        <CalendarComponent
          startDate={startDate}
          endDate={endDate}
          office={currentOffice == "Alle" ? undefined : currentOffice}
        />
      </div>
    </>
  );
}

export function CalendarView() {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <InnerCalendarView />
    </MsalAuthenticationTemplate>
  );
}
