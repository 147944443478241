import { Button, Segmented, Select } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Dispatch, useEffect, useState } from "react";
import { getRangeEndpoints, nextRange, prevRange } from "./RangeUtils";
import { format, getQuarter, getWeek } from "date-fns";
import { de } from "date-fns/locale";
import { useOffices } from "../hooks";
import { Office } from "../models";

const { Option } = Select;

export type CalendarNavigationProps = {
  setOffice: Dispatch<React.SetStateAction<Office | "Alle">>;
  setStart: Dispatch<React.SetStateAction<Date>>;
  setEnd: Dispatch<React.SetStateAction<Date>>;
};

export function CalendarNavigation({
  setOffice,
  setStart,
  setEnd,
}: CalendarNavigationProps) {
  const now = new Date();
  const [division, setDivision] = useState<"month" | "week" | "quarter">(
    "month"
  );
  const [period, setPeriod] = useState(now.getMonth() + 1);
  const [year, setYear] = useState(now.getFullYear());
  const [periodDisplay, setPeriodDisplay] = useState("");
  const { offices, isLoading } = useOffices();
  const [width, setWidth] = useState(105);

  useEffect(() => {
    const { start, end } = getRangeEndpoints(division, period, year);
    setStart(start);
    setEnd(end);

    let display = "";

    if (division == "week" || division == "quarter") {
      display = `${format(start, "dd. MMM", { locale: de })} - ${format(
        end,
        "dd. MMM yyyy",
        { locale: de }
      )}`;
      setWidth(160);
    } else {
      display = format(start, "MMMM yyyy", { locale: de });
      setWidth(105);
    }

    setPeriodDisplay(display);
  }, [period, division]);

  return (
    <span
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "15px 0px",
      }}
    >
      <Select
        defaultValue={"Alle"}
        style={{ width: 150 }}
        loading={isLoading}
        onChange={(value: Office | "Alle") => setOffice(value)}
      >
        <Option value="Alle">Alle</Option>
        {offices.map((office) => (
          <Option key={office} value={office}>
            {office}
          </Option>
        ))}
      </Select>
      <span style={{ display: "flex", columnGap: 10, alignItems: "center" }}>
        <Button
          icon={<LeftOutlined />}
          type="text"
          onClick={() => {
            const { year: newYear, period: newPeriod } = prevRange(
              period,
              year,
              division
            );
            setPeriod(newPeriod);
            setYear(newYear);
          }}
        />
        <span style={{ width: width, textAlign: "center" }}>{periodDisplay}</span>
        <Button
          icon={<RightOutlined />}
          type="text"
          onClick={() => {
            const { year: newYear, period: newPeriod } = nextRange(
              period,
              year,
              division
            );
            setPeriod(newPeriod);
            setYear(newYear);
          }}
        />
      </span>
      <Segmented
        options={["Woche", "Monat", "Quartal"]}
        defaultValue={"Monat"}
        onChange={(value) => {
          const now = new Date();
          setYear(now.getFullYear());

          if (value == "Woche") {
            setDivision("week");
            setPeriod(
              getWeek(now, { weekStartsOn: 1, firstWeekContainsDate: 4 })
            );
          } else if (value == "Monat") {
            setDivision("month");
            setPeriod(now.getMonth() + 1);
          } else if (value == "Quartal") {
            setDivision("quarter");
            setPeriod(getQuarter(now));
          }
        }}
      ></Segmented>
    </span>
  );
}
