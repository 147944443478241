import { format, isSameDay, isSaturday, isSunday } from "date-fns";
import { de } from "date-fns/locale";
import { TimelineHeaders } from "react-headless-timeline";

export type CalendarHeaderProps = {
  cells: number;
  employeeCount: number;
  colWidth: number;
};

export function CalendarHeader({
  cells,
  employeeCount,
  colWidth,
}: CalendarHeaderProps) {
  return (
    <TimelineHeaders
      cells={cells}
      render={({ headers, getHeaderStyles }) => {
        return (
          <span style={{ position: "relative", minWidth: 0 }}>
            {headers.map((header) => (
              <div
                className="header-col"
                key={header.toISOString()}
                style={{
                  ...getHeaderStyles(header),
                  width: colWidth,
                  height: 48 + employeeCount * 34,
                  background: getBackgroundColor(header),
                }}
              >
                <div
                  className="date-overlap"
                  style={{
                    width: colWidth,
                    background: getBackgroundColor(header),
                    fontSize: colWidth >= 70 ? "14px" : "12px",
                    lineHeight: cells > 31 ? undefined : "48px",
                    overflow: cells <= 31 ? "hidden" : undefined,
                    whiteSpace: cells <= 31 ? "nowrap" : undefined,
                  }}
                >
                  {format(header, "eeeeee. dd.MM.", { locale: de })}
                </div>
              </div>
            ))}
          </span>
        );
      }}
    />
  );
}

const getBackgroundColor = (date: Date) => {
  if (isSameDay(new Date(), date)) return "#ffdcdc";

  if (isSaturday(date) || isSunday(date)) return "#f3f3f3";

  return "#fff";
};
