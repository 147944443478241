import { Routes, Route } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { CalendarView } from "./CalendarView";

type AppProps = {
  pca: IPublicClientApplication;
};

export function App({ pca }: AppProps) {
  return (
    <MsalProvider instance={pca}>
      <Routes>
        <Route path="/" element={<CalendarView />} />
      </Routes>
    </MsalProvider>
  );
}
