import { format, isEqual } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { de } from "date-fns/locale";
import { EntryAbsence } from "../models";
import { getAbsenceBackground, getAbsenceColour } from "./EventUtils";

export type PopoverComponentProps = {
  event: EntryAbsence;
  employeeName: string;
};

export function PopoverComponent({ event, employeeName }: PopoverComponentProps) {
  const startDate = zonedTimeToUtc(new Date(event.from), "UTC");
  const endDate = zonedTimeToUtc(new Date(event.thru), "UTC");

  const fmt = "dd. MMMM";
  const displayString = isEqual(startDate, endDate) ? format(startDate, fmt, { locale: de }) : format(startDate, fmt, { locale: de }) + " - " + format(endDate, fmt, { locale: de });

  return (
    <>
      <h3>{event.type}</h3>
      <div
        style={{
          height: 4,
          backgroundColor: getAbsenceColour(event.type),
          backgroundImage: getAbsenceBackground(event)
        }}
      />
      {!event.isActive && <h4>Abwesenheit unbestätigt</h4>}
      <div>{employeeName}</div>
      <div>{displayString}</div>
    </>
  );
}
