import { createRoot } from "react-dom/client";
import { App } from "../App";
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig } from "../config/authConfig";
import { BrowserRouter } from "react-router-dom";
export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <App pca={msalInstance} />
  </BrowserRouter>
);
