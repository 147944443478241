import { format } from "date-fns";
import { useEffect, useState } from "react";
import { API_URL } from "../config/constants";
import { Office, Employee, CalendarEntry, Calendar } from "../models";
import { getAccessToken } from "../services/authService";

const fetcher = async (ressource: string, signal?: AbortSignal) => {
  const accessToken = await getAccessToken();

  return fetch(API_URL + "/api/calendar" + ressource, {
    signal: signal,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((res) => res.json());
};

const cache = new Map<string, Array<CalendarEntry>>();

export const useCalendarEntries = (
  from: Date,
  till: Date,
  office?: Office,
  allEmployees?: Array<Employee>
) => {
  const [entries, setEntries] = useState(Array<CalendarEntry>);
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let ignore = false;
    const controller = new AbortController();
    setIsLoading(true);

    const key = `?from=${format(from, "yyyy-MM-dd")}&thru=${format(
      till,
      "yyyy-MM-dd"
    )}`;

    if (office) {
      const keyWithOffice = key + `&office=${office}`;
      if (cache.has(keyWithOffice)) {
        setEntries(cache.get(keyWithOffice));
        setIsLoading(false);
      } else if (cache.has(key)) {
        const filteredEmployees = allEmployees.filter(
          (empl) => empl.officeName == office
        );
        const filtetred = cache
          .get(key)
          .filter(
            (entry) =>
              filteredEmployees.find(
                (empl) => empl.name == entry.employeeName
              ) != undefined
          );

        setEntries(filtetred);
        cache.set(keyWithOffice, filtetred);
        setIsLoading(false);
      } else {
        fetcher(keyWithOffice, controller.signal).then(
          (result: Calendar) => {
            cache.set(keyWithOffice, result.entries);
            if (!ignore) {
              setEntries(result.entries);
              setIsLoading(false);
            }
          },
          (error: Error) => setError(error)
        );
      }
    } else {
      if (cache.has(key)) {
        setEntries(cache.get(key));
        setIsLoading(false);
      } else {
        fetcher(key, controller.signal).then(
          (result: Calendar) => {
            cache.set(key, result.entries);
            if (!ignore) {
              setEntries(result.entries);
              setIsLoading(false);
            }
          },
          (error: Error) => setError(error)
        );
      }
    }

    return () => {
      ignore = true;
      controller.abort();
    };
  }, [from, till, office]);

  return {
    entries: entries,
    isLoading: isLoading,
    error: error,
  };
};
