import { useEffect, useState } from "react";
import { API_URL } from "../config/constants";
import { Office } from "../models/Office";
import { getAccessToken } from "../services/authService";

export const useOffices = () => {
  const [offices, setOffices] = useState(Array<Office>);
  const [error, setError] = useState<Error>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      void getAccessToken().then((token) => {
        fetch(API_URL + "/api/metadata/offices", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then(
            (result: Array<Office>) => {
              setIsLoading(false);
              setOffices(result);
            },
            (error: Error) => {
              setIsLoading(false);
              setError(error);
            }
          );
      });
    }
  }, []);

  return {
    offices: offices,
    isLoading: isLoading,
    error: error,
  };
};
