import { AbsenceType, EntryAbsence } from "../models";

export function getAbsenceColour(type: AbsenceType): string {
  let colour: string;
  switch (type) {
    case "Abwesend":
      colour = "#B33B3B";
      break;
    case "Feiertag":
    case "Feiertag(halbtags)":
      colour = "#D36135";
  }

  return colour;
}

export function getAbsenceBackground(event: EntryAbsence): string {
  let background = "";

  if (!event.isActive) {
    background = "repeating-linear-gradient(45deg, transparent, transparent 13px, rgba(255,255,255,.5) 13px, rgba(255,255,255,.5) 20px)"
  }
  if (event.type == "Feiertag(halbtags)") {
    background = "repeating-linear-gradient(90deg, transparent, transparent 10px, rgba(255,255,255,.5) 10px, rgba(255,255,255,.5) 20px)"
  }

  return background;
}
