import { msalInstance } from "../bootstrap";

export async function getAccessToken(): Promise<string> {
  const account = msalInstance.getActiveAccount();

  const response = await msalInstance.acquireTokenSilent({
    scopes: ["api://c8320bbf-c4cf-4602-8630-5ea24a3f2b2c/access"],
    account,
  });

  return response.accessToken;
}
